.accordion-button:not(.collapsed) {
  color: $color-primary;
  background-color: $color_tableHead;
}

.links {
  text-decoration: underline;
  color: blue;
}

.text-address {
  font-size: 14px;
}

.text-label {
  font-size: 14px !important;
}

.calendar-item {
  height: 2em;
}

.text-warning {
  color: red;
  font-size: 10px;
}

info-icon {
  color: $color_primary;
}

.report-input {
  height: 38px;
}

/** ------- Reports content ------- */
.custom-text {
  color: $color_primary !important;
  font-size: 18px;
}

.card-header,
.modal-header {
  background-color: $line-color-active;
}

.shadow {
  box-shadow: $shadow_card !important;
}

/** ------- GENERATION xls CARDS ------- */
.card-modal {
  width: 180px;
  height: 180px;
  position: relative;
  overflow: visible;
  margin: 20px;
  box-shadow: $shadow_card;
  display: inline-block;
  border-radius: 50px;
}

.set-image {
  width: 100%;
  height: 50%;
  overflow: hidden;
  display: table;
}

.ribbon-wrapper-2 {
  width: 108px;
  height: 108px;
  overflow: hidden;
  position: absolute;
  top: -6px;
  right: -6px;
}

.ribbon-2 {
  font: $font-ribbon;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  transform: rotate(45deg);
  position: relative;
  padding: 7px 0;
  left: -4px;
  top: 26px;
  width: 150px;
  background-color: $color-ribbon-bg;
  color: $color-ribbon-text1;
  letter-spacing: 0.5px;
  box-shadow: -3px 5px 6px -5px rgb(0 0 0 / 50%);

  &::before,
  &::after {
    content: '';
    border-top: 4px solid $color-ribbon-border;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    position: absolute;
    bottom: -4px;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 100%;
    z-index: -1;
    border-left: 4px solid $color-ribbon-border;
    border-right: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-top: 4px solid $color-ribbon-border;
  }

  &::after {
    content: '';
    position: absolute;
    right: 7px;
    top: 100%;
    z-index: -1;
    border-left: 4px solid transparent;
    border-right: 4px solid $color-ribbon-border;
    border-bottom: 4px solid transparent;
    border-top: 4px solid $color-ribbon-border;
  }
}

.stock {
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.h-38 {
  height: 38px;
}
