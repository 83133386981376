// /** Custom Table icon hovering style */

/** Search part styles */
.filter-search {
  max-width: 350px;
  border-radius: 5px;
  box-shadow: 0 1.2px 3.5px 0 rgb(0 0 0 / 6%);

  .input-group-text {
    border: 1px solid #e9ecef;
    padding-right: 0;
    color: #b9b9c3;
    background-color: transparent;
  }

  .form-control {
    border: 1px solid #e9ecef;
    border-left: none !important;
  }
}

.transa-height {
  @media (width >= 992px) {
    height: $min-contentHeight;
  }

  @media (width >= 1200px) {
    height: $min-contentHeight;
  }

  @media (width >= 1400px) {
    height: $min-contentHeight;
  }

  @media (width >= 1920px) {
    height: $min-contentHeight;
  }

  @media (width >= 2560px) {
    height: $min-contentHeight;
  }

  @media (width >= 3840px) {
    height: $min-contentHeight;
  }
}

.color-btn {
  color: $color_primary;
  background-color: $color_secondary !important;

  &:hover {
    color: white;
    background-color: $color_primary !important;
  }
}

.table > :not(:first-child) {
  border-top: 0 solid $border-color;
}

.tx-table {
  thead {
    font: $font_type6;
    color: $color_primary;
    background-color: $line-color-active;

    tr {
      th {
        vertical-align: middle;
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: $line-color-active;

        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          text-align: center;
        }
      }
    }
  }

  .tx-body {
    > tr td:nth-child(1) {
      font: $font_type4;
      width: 3%;
    }

    > tr td:nth-child(2) {
      font: $font_type4;
      color: black;
      font-weight: 500;
      padding-right: 5px;
      width: 19%;
    }

    > tr td:nth-child(3) {
      font: $font_type4;
      color: $font_color_type4;
      padding-right: 5px;
      width: 22%;
    }

    > tr td:nth-child(4) {
      width: 16%;
      font: $font_type5;
      padding-right: 5px;
      color: $color_primary;
    }

    > tr td:nth-child(5),
    > tr td:nth-child(7),
    > tr td:nth-child(9) {
      width: 4%;
      font: $font_type4;
      padding-right: 5px;
      text-align: end;
      color: $color_primary;

      > table tbody tr td:nth-child(1) {
        width: 4%;
        font: $font_type4;
        padding-right: 5px;
        text-align: end;
        color: $color_primary;
        vertical-align: top;
      }

      > table tbody tr td:nth-child(2) {
        width: 6%;
        text-align: start;
        color: $color_primary;
        vertical-align: top;
      }
    }

    > tr td:nth-child(6),
    > tr td:nth-child(8),
    > tr td:nth-child(10) {
      width: 6%;
      text-align: start;
      color: $color_primary;
    }

    > tr td:nth-child(11) {
      vertical-align: top;
      width: 10%;
    }
  }

  &-quantity {
    font: $font_type7;
    display: inline-block;
  }

  &-currency {
    font: $font_type8 !important;
    margin-bottom: 0;
    vertical-align: sub;
    text-align: initial !important;
    min-width: 4em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .stylebtn {
      font: $font_type8 !important;
      text-align: initial !important;
    }
  }
}

.total {
  font: $font_type7;
  color: $color_primary;
}

/** Custom Table Layouts style */
.categ-layout {
  padding: 3px 11px 3px 10px;
  font-weight: bold;
  background-color: rgb(180 207 228);
  border-radius: 10px;
  display: inherit;

  &-ignored {
    padding: 3px 11px 3px 10px;
    font-weight: bold;
    border-radius: 10px;
    display: inherit;
    background-color: $color_inactive !important;
  }

  &-unknown {
    padding: 3px 11px 3px 10px;
    font-weight: bold;
    border-radius: 10px;
    display: inherit;
    color: $color-table-red !important;
    background-color: $color_item_red !important;
  }

  &-depwith {
    padding: 3px 11px 3px 10px;
    font-weight: bold;
    border-radius: 10px;
    display: inherit;
    color: $color_table_yellow !important;
    background-color: $color_item_yellow !important;
  }

  &-ico {
    padding: 3px 11px 3px 10px;
    font-weight: bold;
    border-radius: 10px;
    display: inherit;
    color: $color_table_green !important;
    background-color: $color_item_green !important;
  }
}

.categ-icon {
  color: $color_secondary;
  cursor: pointer;
  margin-left: 6px;
}

.item-layout {
  padding: 3px 11px 3px 10px;
  font-weight: bold;
  border-radius: 10px;
  display: inherit;

  &.green {
    background-color: $color_item_green;
    color: $color-table-green;
  }

  &.yellow {
    background-color: $color_item_yellow;
    color: $color-table-yellow;
  }

  &.red {
    background-color: $color_item_red;
    color: $color-table-red;
  }
}

.line-height- {
  &tx {
    border-bottom: 1px solid $border-color;

    > .deactivate-line {
      background-color: gray;
      color: black;
    }

    &.none {
      height: 60px;
      text-align: center;
      vertical-align: middle;

      td {
        font: $font_type7 !important;
        color: $color_primary !important;
        font-weight: 700 !important;
      }
    }
  }
}

input[type='checkbox'].check-layout {
  padding: 0 0 0 5px !important;
  margin: 0 0 0 10px;
  height: 15px;
  width: 15px;
}

.with-width {
  &1 {
    width: 33%;
    font-size: small;
  }

  &2 {
    width: 40%;
    font-size: small;
    text-align: end;
  }

  &3 {
    font-size: small;
    width: 19%;
  }
}

.custom-ico {
  height: 22em !important;
  overflow-y: scroll;

  &2 {
    height: 11em !important;
    overflow-y: scroll;
  }
}

.ico-width {
  &1 {
    width: 30%;
  }

  &2 {
    width: 30%;
    text-align: end;
  }

  &3 {
    width: 10%;
  }

  &4 {
    width: 20%;
  }
}

.custom-neg {
  height: 15em !important;
  overflow-y: scroll;
}

.neg-width {
  &1 {
    font-size: small;
    width: 16%;
  }

  &2 {
    font-size: small;
  }

  &3 {
    font-size: small;
    width: 17%;
  }

  &4 {
    font-size: small;
    width: 10%;
  }

  &5 {
    font-size: small;
    width: 5%;
  }
}

.neg-scroll {
  height: 10em;
  overflow-y: scroll;
}

.font-small {
  font-size: small;
}

/** MODAL */
.sort-icon {
  color: $color_primary;
}

.color {
  color: $color_primary;
}

input[type='number' i] {
  padding: 1px 2px;
  width: 4em;
}

.label-paddingtop {
  padding-top: 0.42rem !important;
  white-space: nowrap;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
}
