.text-tools {
  font-size: 14px;

  &-nota {
    color: red;
    font-size: 10px;
  }

  &-notablack {
    color: black;

    > a {
      color: blue;
      text-decoration: underline;
    }
  }
}

.link {
  color: blue;
  text-decoration: underline;
}

.spinner {
  width: 50%;
}

.line-height- {
  &tools {
    border-bottom: 1px solid $border-color;
    color: $color_primary;

    &.none {
      height: 60px;
      text-align: center;
      vertical-align: middle;
    }
  }
}

.custom-cardtool {
  border-radius: 15px;
  box-shadow: $shadow_card;
  // margin: 0px 10px 15px 0px;
}
