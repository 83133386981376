.help {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  box-shadow: $shadow_card;
  position: absolute;
  z-index: 1000;
  background-color: transparent;
  bottom: 0;
  right: 0;

  &:hover {
    background-color: white;
  }

  &-button {
    background-color: transparent;
    border: none;
    padding: 0;
    color: $color_secondary;
    text-decoration: none;
    position: absolute;
    left: 9%;
    top: 9%;

    &:hover {
      color: $color_primary;
    }
  }
}

.heartbeat {
  animation: heartbeat 1.5s ease-in-out infinite both;
}

.zindex {
  z-index: 1500;
}

/** Interactive Modals styles */
.customDefault {
  color: $color_primary;
  font-size: 14px;

  .introjs-prevbutton {
    background-color: $color_secondary;
    color: $color_primary;
    text-shadow: none !important;

    &:hover {
      background-color: $color_primary;
      color: white;
      text-shadow: none !important;
    }
  }

  .introjs-nextbutton {
    background-color: $color_secondary;
    color: $color_primary;
    text-shadow: none !important;

    &:hover {
      background-color: $color_primary;
      color: white;
      text-shadow: none !important;
    }
  }

  .introjs-donebutton {
    background-color: $color_secondary;
    color: $color_primary;
    text-shadow: none !important;

    &:hover {
      background-color: $color_primary;
      color: white;
      text-shadow: none !important;
    }
  }

  .introjs-disabled {
    background-color: $gray-600;
    color: white;

    &:hover {
      background-color: $gray-600;
      color: white;
    }

    &:focus {
      background-color: $gray-600;
      color: white;
    }
  }
}

.pulsate-fwd {
  animation: pulsate-fwd 1s ease-in-out 3s 4 both;
}
