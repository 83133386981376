/** WALLETS PAGE */
@media (width <= 575px) {
  .car {
    height: 28rem;
    justify-content: center;
  }
}

@media (width >= 576px) {
  .car {
    height: 18rem;
    justify-content: center;
  }

  .wallets {
    height: 20rem;
    overflow-y: scroll;
    overflow: auto;
    scrollbar-width: thin;
  }
}

@media (width >= 992px) {
  .car {
    height: 28rem;
    justify-content: center;
  }

  .wallets {
    height: 28rem;
    overflow-y: scroll;
    overflow: auto;
    scrollbar-width: thin;
  }
}

@media (width >= 1200px) {
  .car {
    height: 35rem;
    justify-content: center;
  }

  .wallets {
    height: 30rem;
    overflow-y: scroll;
    overflow: auto;
    scrollbar-width: thin;
  }
}

@media (width >= 1920px) {
  .car {
    height: 40rem;
    justify-content: center;
  }

  .wallets {
    height: 38rem;
    overflow-y: scroll;
    overflow: auto;
    scrollbar-width: thin;
  }
}

@media (width >= 2560px) {
  .car {
    height: 58rem;
    justify-content: center;
  }

  .wallets {
    height: 55rem;
    overflow-y: scroll;
    overflow: auto;
    scrollbar-width: thin;
  }
}

@media (width >= 3840px) {
  .car {
    height: 68rem;
    justify-content: center;
  }

  .wallets {
    height: 65rem;
    overflow-y: scroll;
    overflow: auto;
    scrollbar-width: thin;
  }
}

/** Carousel styles */
.car-items {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  padding: 1rem 0;
  scroll-snap-type: y mandatory;
  scrollbar-width: thin;

  li {
    list-style: none;
  }
}

.car-item {
  flex-grow: 1;
  flex-shrink: 0;
  width: 7.3rem;
  margin-left: 1rem;
  scroll-snap-align: start;
  padding: 2px;
  margin: 5px;

  &-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 6.4rem;
    position: relative;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    padding: 0.5rem;
    border: 2px solid rgb(0 0 0 / 5%);
    border-radius: 10px;
    text-decoration: none !important;

    &-title {
      text-decoration: none;
      text-align: center;
      font-family: $font-quicksand;
      font-size: 12px;
      font-weight: 500;
      color: $color_primary;
      height: 36px;
      padding: 0;
      margin: 0;
    }

    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
  }
}

.selectable-wallet {
  border: 2px solid rgb(0 0 0 / 5%);
  border-radius: 10px;
  cursor: pointer;

  &.selected {
    border-color: $color_secondary;
  }

  &:hover {
    border-color: $color_secondary;
  }

  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
}

::-webkit-scrollbar-track {
  background-color: white;
}

::-webkit-scrollbar {
  height: 5px;
  background-color: white;
}

::-webkit-scrollbar-thumb {
  background-color: #3d4852;
  border-radius: 3px;
}

/** File import styles */
.add- {
  &import {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    &-title {
      font: $font_type3;
      line-height: 150%;
      color: #0d426e;
      margin: 0;
    }

    &-fichier {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: $color_primary;
      font-family: Inter, sans-serif;
      font-size: 10px;
      font-style: italic;
    }
  }
}

@media (width <= 575px) {
  .width-media {
    width: 100%;
  }
}

@media (width >= 576px) {
  .width-media {
    width: 100%;
  }
}

@media (width >= 992px) {
  .width-media {
    width: 75%;
  }
}

@media (width >= 1200px) {
  .width-media {
    width: 50%;
  }
}

/** History table styles */
.wallet-table {
  box-shadow: $shadow_type1;

  thead {
    font: $font_type6;
    color: $color_primary;
    background-color: $line-color-active;

    tr {
      th {
        vertical-align: middle;
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: $line-color-active;
      }
    }
  }

  tbody {
    > tr td:nth-child(1) {
      font: $font_type4;
      color: black;
      font-weight: 500;
      padding-right: 5px;
    }

    > tr td:nth-child(2) {
      font: $font_type4;
      color: $font_color_type4;
      padding-right: 5px;
    }

    > tr td:nth-child(3) {
      font: $font_type5;
      padding-right: 5px;
      color: $color_primary;
      width: 25%;
    }

    > tr td:nth-child(4) {
      font: $font_type5;
      padding-right: 5px;
      color: $color_primary;
    }

    > tr td:nth-child(5) {
      font: $font_type5;
      padding-right: 5px;
      color: $color_primary;
    }

    > tr td:nth-child(6) {
      font: $font_type5;
      padding-right: 5px;
      color: $color_primary;
    }
  }
}

.collapse-icon {
  border: none;
  color: $color_secondary;
  font-size: inherit;
  padding: 0;

  &:hover {
    color: $color_primary;
  }

  transition: $accordion-icon-transition;
}

.collapse-icon:focus {
  box-shadow: none;
}

.collapse-icon:not(.collapsed) {
  transform: $accordion-icon-transform;
}

.wallet-action-icon {
  border: none;
  color: $color_secondary;
  font-size: inherit;
  padding: 0;

  &:hover {
    color: $color_primary;
  }
}

.wallet-action-icon-locked {
  color: $color_cancel;
}

.line-height- {
  &wallets {
    border-bottom: 1px solid $border-color;

    &.none {
      height: 60px;
      text-align: center;
      vertical-align: middle;

      td {
        font: $font_type7 !important;
        color: $color_primary !important;
        font-weight: 700 !important;
      }
    }

    &.expanded {
      background-color: $color_tableHead;
    }
  }
}

.scroll-behaviour-smooth {
  scroll-behavior: smooth;
}

/** Wallet cards styles */
.wallet-card {
  box-shadow: $shadow_card;
  border-radius: 15px;
  margin-top: 2.5rem;
}

.logo {
  justify-content: flex-start;
  width: 40%;
  margin: 1% 3%;
  align-items: center;

  > img {
    width: 100px;
    height: 100px;
    padding: 0 !important;
  }

  > p {
    text-align: center;
    font: $font_type1;
    font-weight: 700;
    font-size: 24px;
    color: #0d426e;
    margin-left: 5%;
    margin: 0 0 0 5%;
  }
}

.remove {
  justify-content: flex-end;
  width: 40%;
  margin: 1% 3%;
  align-items: center;

  > svg {
    width: 40px;
    height: 40px;
    color: $color_secondary;
    padding: 0 !important;
  }

  > p {
    text-align: center;
    font: $font_type4;
    color: #0d426e;
    margin: 0 1% 0 0;
    width: 84px;
  }
}

.custom-table {
  width: 50%;

  tr {
    td:nth-child(1) {
      text-align: right;
      vertical-align: baseline;
    }

    .input-group {
      width: 450px;
      margin-left: 15px;
    }
  }
}

.custom-card {
  border-top: 4px solid $color_secondary !important;
  box-shadow: $shadow_card;

  .card-body {
    padding-bottom: 0 !important;

    .card-title {
      margin: 0;
      text-align: left;
      color: $color_primary;
      font-weight: 400;
      font-size: 21px;
    }

    .card-text {
      text-align: right;
      color: $color_primary;
      font-weight: bold;

      &:last-child {
        margin-bottom: -7% !important;
      }
    }
  }
}

.btn-info {
  color: #fff;
  background-color: $color_primary;
  border-color: $color_primary;
  box-shadow: none;

  &:hover,
  &:focus {
    background-color: $color_secondary;
    color: $color_primary;
  }
}

.separator- {
  &one {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &two {
    margin-top: 2rem;
  }
}
