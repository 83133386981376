/** ------- Global styles/Fonts ------- */
$font-prim: 'Inter', sans-serif; // old font-family1
$font-sec: 'Lato', sans-serif; // old font-family2
$font-quicksand: 'Quicksand', sans-serif;

$font_headtitle: 900 40px $font-sec, helvetica, arial, serif;
$font_headtitle_resp: 700 30px $font-sec, helvetica, arial, serif;
$font_headsubtitle: 400 16px $font-prim, helvetica, arial, serif;

$font_type0: 500 14px/1.2 $font-prim, helvetica, arial, serif;
$font_type1: 500 1em/1.2 $font-prim, helvetica, arial, serif;
$font_type2: 1em/1.2 $font-prim, helvetica, arial, serif;
$font_type3: 700 1.2857142857142858em/1.2 $font-prim, helvetica, arial, serif;
$font_type4: 13px/1.2 $font-prim, helvetica, arial, serif;
$font_type5: 11px/1.2 $font-prim, helvetica, arial, serif;
$font_type6: 500 12px/1.2 $font-prim, helvetica, arial, serif;
$font_type7: 500 15px/1.2 $font-prim, helvetica, arial, serif;
$font_type8: 500 12px/1.2 $font-prim, helvetica, arial, serif;

$font_def1: 500 16px/1.2 $font-prim, helvetica, arial, serif;
$font_def2: 16px/1.2 $font-prim, helvetica, arial, serif;

$font_color_type1: rgb(255 255 255);
$font_color_type2: rgb(245 251 255);
$font_color_type3: rgb(181 208 229);
$font_color_type4: #5983a5;

/** ------- Global styles/colors ------- */
$color_primary: #0d426e;
$color_primary-var: #5983a5;
$color_secondary: rgb(230 193 110 / 75%);
$color_cancel: $secondary;
$color_sidebarNav: #f0f7ff;
$color_background: white;
$color_tableHead: #f5faff;
$color_inactive: #c7c7c7;
$color_greyText: rgb(174 174 174 / 95%);
$color_greySynth: #aeaeae;
$color_blackText: #404040;

$color_synth_green: #439a86;
$color_synth_red: #bb4430;

$color_table_yellow: #965e00;
$color-table-red: #d30000;
$color-table-green: #007f00;

$color_item_green: #cdffcd;
$color_item_yellow: #ffeccc;
$color_item_red: #ffe0e0;

/** ------- Global styles/formating ------- */
$line-color-active: #f5faff;
$line-color-titleActive: #f5f4f9;

$border-color: #b4cfe4;

$border_radius_type0: 9.5px 9.5px 9.5px 9.5px;
$border_radius_type1: 6px 6px 6px 6px;

$border_radius_type2: 15px;
$shadow_type1: 0 16px 24px rgb(0 0 0 / 6%), 0 2px 6px rgb(0 0 0 / 4%), 0 0 1px rgb(0 0 0 / 4%);

$side_width: 244px;
$header-width: calc(100vw - calc($side_width + 16px));

$header_height: 100px;
$min-wrapperHeight: 100vh;
$min-contentHeight: calc(100% - $header_height);

$shadow_card: 0 16px 24px rgb(0 0 0 / 6%), 0 2px 6px rgb(0 0 0 / 4%), 0 0 1px rgb(0 0 0 / 4%);

/** For RIBBONS */
$color-ribbon-bg: $color_secondary;
$color-ribbon-border: #4e7c7d;
$color-ribbon-text1: $color_blackText;
$color-ribbon-text2: #333;
$font-ribbon: 900 24px 'Quicksand', sans-serif;
