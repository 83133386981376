.def-table {
  scroll-behavior: smooth;

  thead {
    font: $font_type1;
    color: $color_primary;
    background-color: $line-color-active;

    tr {
      th {
        vertical-align: middle;
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: $line-color-active;
      }
    }
  }

  tbody {
    > tr td:nth-child(1) {
      font: $font_def1;
      color: black;
      padding-right: 5px;
    }

    > tr td:nth-child(2) {
      font: $font_def2;
      color: $font_color_type4;
      padding-right: 5px;
    }
  }
}
