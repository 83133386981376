/** ------- Boostrap styles ------- */
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/bootstrap';

/** ------- IntroJS styles ------- */
@import 'intro.js/introjs';

/** ------- Custom global variables ------- */
@import 'fonts';
@import 'variables';
@import 'animations';

/** Styles for secHeader and Sidebar */
@import 'sidebarCustom';
@import 'headerCustom';
@import 'transactions';
@import 'synthesis';
@import 'calendar';
@import 'reports';
@import 'wallets';
@import 'definitions';
@import 'tools';
@import 'help';
@import 'documentation';

::-webkit-scrollbar {
  background-color: $color_secondary;
}

::-webkit-scrollbar-thumb {
  background-color: $color_secondary;
}

::-webkit-scrollbar-thumb {
  background-color: $color_secondary;
  border-color: $color_secondary;
}

.offcanvas-start {
  width: 270px !important;
}

.w-90 {
  width: 90% !important;
}

.w-70 {
  width: 70%;
}

.w-65 {
  width: 65%;
}

.w-35 {
  width: 35%;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15%;
}

.w-30 {
  width: 30%;
}

.h-90 {
  height: 90% !important;
}

.btn-primary:focus {
  color: #fff;
  background-color: $color_primary;
  border-color: $color_primary;
}

.btn- {
  &primary {
    background-color: $color_secondary;

    &:hover {
      background-color: $color_primary;
    }
  }
}

.form-check-input:checked {
  background-color: $color_secondary;
  border-color: $color_secondary;
}

.form-check-input:indeterminate[type='checkbox'] {
  background-color: $color_secondary !important;
  border-color: $color_secondary !important;
}

.links {
  text-decoration: underline;
  color: #0d6efd;
}

.red {
  color: $color_synth_red;
}

.textsm {
  font-size: 14px;
}

.color {
  color: $color_primary;
}

textarea {
  resize: none;

  // white-space: normal;
}

/* this is needed to make the content scrollable on larger screens */
@media (width >= 576px) {
  .h-sm-100 {
    height: 100%;
  }

  .mainframe {
    height: $min-contentHeight;
  }
}

@media (width >= 992px) {
  .vh100 {
    height: 100vh !important;
  }
}
