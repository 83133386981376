.calendar-icon {
  background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%221em%22%20height%3D%221em%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20d%3D%22M480%20128a64%2064%200%200%200-64-64h-16V48.45c0-8.61-6.62-16-15.23-16.43A16%2016%200%200%200%20368%2048v16H144V48.45c0-8.61-6.62-16-15.23-16.43A16%2016%200%200%200%20112%2048v16H96a64%2064%200%200%200-64%2064v12a4%204%200%200%200%204%204h440a4%204%200%200%200%204-4z%22%20fill%3D%22%230d426e%22%2F%3E%3Cpath%20d%3D%22M32%20416a64%2064%200%200%200%2064%2064h320a64%2064%200%200%200%2064-64V180a4%204%200%200%200-4-4H36a4%204%200%200%200-4%204z%22%20fill%3D%22%230d426e%22%2F%3E%3C%2Fsvg%3E');
  background-size: 17px 17px !important;
}

.custom-modalbody {
  line-height: 1.3em;
  height: 25em;
  overflow-y: scroll;
}

.history-tl-container {
  font-family: $font-quicksand;
  width: 50%;
  margin: auto;
  display: block;
  position: relative;
}

.history-tl-container ul.tl {
  margin: 20px 0;
  padding: 0;
  display: inline-block;
}

.history-tl-container ul.tl li {
  list-style: none;
  margin: auto;
  margin-left: 20px;
  min-height: 50px;
  border-left: 1px dashed #86d6ff;
  padding: 0 0 50px 30px;
  position: relative;
}

.history-tl-container ul.tl li:last-child {
  border-left: 0;
}

.history-tl-container ul.tl li::before {
  position: absolute;
  left: -11px;
  top: -5px;
  content: ' ';
  border: 8px solid rgb(255 255 255 / 74%);
  border-radius: 500%;
  background: $color_primary;
  height: 20px;
  width: 20px;
  transition: all 500ms ease-in-out;
}

.history-tl-container ul.tl li:hover::before {
  border-color: $color_secondary;
  transition: all 1000ms ease-in-out;
}

ul.tl li {
  .item-detail {
    color: rgb(0 0 0 / 50%);
    font-size: 12px;
  }

  .timestamp {
    color: #8d8d8d;
    position: absolute;
    width: 100px;
    left: -60%;
    text-align: right;
    font-size: 12px;
  }
}

.yellow-text {
  color: $color_secondary;
}

.text {
  font-size: 14px;
}
