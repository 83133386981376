// /** ------- Brand Logo formating ------- */
.logo- {
  &cff {
    width: 48px;
    height: 48px;
  }

  &text {
    font-family: $font-quicksand;
    font-size: 16px;
    font-weight: 700;
    color: $color_primary;
  }
}

/** ------- Navbar formating ------- */
.nav-link {
  display: flex;
  align-items: center;
  color: $color_greyText;

  @media (width >= 575px) {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
    margin-left: 1rem;
  }

  > .nav-font {
    margin: 2.5px;
    font-family: $font-sec;
    font-size: 17px;
    font-weight: 400;
  }

  &:hover,
  :focus {
    color: $color_primary;
  }

  &.active {
    background-color: $color_sidebarNav !important;
    color: $color_primary !important;
    box-shadow: none;
    border-radius: 0.9rem;
  }
}

.custom-nav-link {
  color: $color_greyText;
}

/** ------- Leave Part formating ------- */
.leave- {
  &comment {
    width: 100%;
    font-family: $font-prim;
    font-size: 9px;
    font-weight: 400;
    font-style: normal;
    color: $color_blackText;
    text-align: center;
    margin-bottom: 5%;

    span {
      text-decoration: underline;
      color: #0d6efd;
    }
  }
}

.btn-style {
  border-radius: 6px;
  color: $color_primary;
  font-family: $font-quicksand;
  font-size: 12px;
  font-weight: 700;
  border-style: hidden;
}

.leave {
  overflow: hidden;
  align-items: center;
}

@media (width <= 575px) {
  .custom-sidebar {
    /** For separator on the right */
    border-bottom: 1px solid rgb(0 0 0 / 10%);
    width: 100%;
    max-height: 80.98px;
  }
}

@media (width >= 576px) {
  .col-sm-3 {
    flex: 0 0 auto;
    width: 39.666667%;
  }

  .custom-sidebar {
    /** For separator on the right */
    border-right: 1px solid rgb(0 0 0 / 10%);
    height: 100vh;
  }

  .max-width {
    max-width: $side_width;
  }

  /** Animation */
  .slide-in-left {
    animation: slide-in-left 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
}

@media (width >= 768px) {
  .col-md-2 {
    flex: 0 0 auto;
    width: 29.666667%;
  }
}

@media (width >= 992px) {
  .col-lg-2 {
    flex: 0 0 auto;
    width: 23.666667%;
  }
}

@media (width >= 1200px) {
  .col-xl-2 {
    flex: 0 0 auto;
    width: 19.666667%;
  }
}
