/** ------- Synthese styles ------- */
@media (width <= 575px) {
  .h-min {
    height: 100vh;
  }

  .h-60 {
    height: 50vh;
  }

  .h-40 {
    height: 50vh;
  }

  .flex-resp {
    flex-direction: column;
  }
}

@media (width >= 576px) {
  .h-min {
    height: $min-contentHeight;
  }

  .h-60 {
    height: 59%;
  }

  .h-40 {
    height: 49%;
  }

  .flex-resp {
    height: 100%;
  }
}

.custom-cardstyle {
  &-balances {
    border-radius: 15px;
    box-shadow: $shadow_card;
    color: $color_primary;
  }

  &-stats {
    border-radius: 15px;
    box-shadow: $shadow_card;
    color: $color_primary;
  }

  &-evolution {
    border-radius: 15px;
    box-shadow: $shadow_card;
    color: $color_primary;
  }

  &-types {
    border: none;
    box-shadow: none;
  }

  &-doc {
    border-radius: calc(0.25rem - 1px);
    border-radius: 0 25px;
  }
}

.custom-walletlist {
  border-radius: 15px;
  box-shadow: $shadow_card;

  .card-title {
    color: $color_primary;
  }

  .card-body {
    padding-top: 0;
    padding-bottom: 0;
  }

  button {
    background-color: $color_background;
    border: 2px solid rgb(0 0 0 / 5%);
    border-radius: 10px;
    margin: 0 0.25rem 0.25rem;
    padding: 0 0.25rem;

    span {
      padding-top: 0.25rem;
      display: block;
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &.selected {
      border-color: $color_secondary;
    }

    &:hover {
      border-color: $color_secondary;
    }
  }
}

.custom-card-doc {
  position: absolute;
  top: 0;
  right: 0;
  left: 3px;
  border: none;
  color: white;
  vertical-align: baseline;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
}

.totals {
  text-align: right;
  font-family: $font-sec;

  /* stylelint-disable-next-line selector-class-pattern */
  .subtotals- {
    &one {
      color: $color_primary;
      font-style: normal;
      font-weight: 900;
      font-size: 36px;
      line-height: 40px;
    }

    &two {
      color: $color_greySynth;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
    }

    &three {
      font-style: normal;
      font-weight: 800;
      font-size: 26px;
      line-height: 30px;
    }

    &four {
      font-style: normal;
      font-weight: 800;
      font-size: 22px;
      line-height: 30px;
    }

    &positive {
      color: $color_synth_green;
    }

    &negative {
      color: $color_synth_red;
    }
  }
}

.custom-cardsynth-parent {
  overflow-x: scroll;

  .custom-cardsynth-fils {
    flex-wrap: nowrap;

    .custom-cardsynth {
      flex: 1 1 0;
      margin: 8.5px !important;
      border-radius: 10px;
      box-shadow: $shadow_type1;

      > div {
        padding: 5px 10px;
        width: 115px;

        > h4 {
          font-size: 14px;
          font-weight: 500;
          padding-left: 2px;
          color: $color_greySynth;
        }

        > h1 {
          font-size: 32px;
          text-align: right;
          color: $color_primary;
        }
      }
    }
  }
}

.figure {
  width: 60%;
}

.rad {
  border-radius: $border_radius_type2;
}

.custom-slide {
  overflow-x: scroll;
  flex-wrap: nowrap;

  > div {
    margin-right: 5px;
    text-align: center;
  }

  > div > img {
    border-radius: 50%;
  }

  > div > p {
    font-size: 12px;
    font-weight: 600;
    text-align: center;
  }
}

.custom-card-2 {
  border: none;
  height: 149px;
  color: white;
  font-weight: 800;

  &:hover,
  :focus {
    color: white;
    text-decoration: underline;
  }

  > img {
    border-radius: 0 25px;
    height: 100%;
    width: 100%;
  }
}

.color {
  color: $color_primary;
}

.small-text {
  font-size: 0.5em;
}

.table-balances {
  box-shadow: $shadow_type1;

  .head {
    font: $font_type6;
    font-size: 0.9em;
    color: $color_primary;
    background-color: $line-color-active;
    font-weight: 700;

    div {
      padding: 0.5rem;
      vertical-align: middle;
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: $line-color-active;
    }
  }

  .body {
    font: $font_type4;
    font-size: 0.9em;
    color: black;
    font-weight: 500;

    .line-balance {
      border-bottom: 1px solid $border-color;

      div {
        padding: 0.5rem;

        &:nth-child(1) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:nth-child(2) {
          color: $font_color_type4;
        }
      }

      &.negative-amount {
        background-color: rgba(red, 0.2);

        div:nth-child(2) {
          color: red;
        }
      }
    }

    .none {
      height: 60px;

      div {
        font: $font_type7 !important;
        color: $color_primary !important;
        font-weight: 700 !important;
      }
    }
  }
}
