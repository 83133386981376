/** ------- Secondary Header ------- */
.gender-img {
  width: 35px;
  height: 35px;
}

.identity {
  font-family: $font-prim;
  font-size: 14px;
  font-weight: 900;
  font-style: normal;
  color: $color_blackText;
}

.number {
  font-family: $font-prim;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  color: $color_greyText;
}

.head-icon {
  font-size: 25px;
  color: $color_greyText;
  position: relative;

  &:hover,
  &:focus {
    color: $color_primary;
  }

  &.engine-halted {
    animation: engine-halted 1s infinite;
    color: $color_synth_red;
  }

  &.engine-loading {
    animation: engine-loading 1s infinite;
    color: $color_secondary;
  }

  &.engine-running {
    color: $color_synth_green;
  }
}

@keyframes engine-halted {
  0% {
    color: $color_greyText;
  }

  100% {
    color: $color_synth_red;
  }
}

@keyframes engine-loading {
  0% {
    color: $color_greyText;
  }

  100% {
    color: $color_secondary;
  }
}

/* Secondary header global frame */
@media (width <= 575px) {
  .header-title {
    font: $font_headtitle_resp;
    font-style: normal;
    line-height: 40px;
  }
}

@media (width >= 576px) {
  .header {
    max-height: $header_height;
  }

  .header-title {
    font: $font_headtitle;
    font-style: normal;
    line-height: 52px;
  }

  .header-subtitle {
    color: $color_greyText;
    font: $font_headsubtitle;
    font-style: normal;
    line-height: 19px;
  }

  /**
 * Nav Header Animations
 */
  .slide-in-top {
    animation: slide-in-top 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
}

.no-underline {
  text-decoration: none;
}
