@media (width <= 575px) {
  .custom-doc {
    position: relative;
    overflow-y: scroll;
  }
}

@media (width >= 576px) {
  .custom-doc {
    position: relative;
    overflow-y: scroll;
    height: 28rem;
  }
}

@media (width >= 992px) {
  .custom-doc {
    position: relative;
    overflow-y: scroll;
    height: 38rem;
  }
}

@media (width >= 1200px) {
  .custom-doc {
    position: relative;
    overflow-y: scroll;
    height: 43rem;
  }
}

@media (width >= 1920px) {
  .custom-doc {
    position: relative;
    overflow-y: scroll;
    height: 49rem;
  }
}

@media (width >= 2560px) {
  .custom-doc {
    position: relative;
    overflow-y: scroll;
    height: 65rem;
  }
}

@media (width >= 3840px) {
  .custom-doc {
    position: relative;
    overflow-y: scroll;
    height: 80rem;
  }
}

.primary {
  color: $color_primary;
}

.list-step {
  ol {
    list-style-type: none;
  }

  ol li::before {
    content: 'Etape ';
  }
}

.donate {
  overflow: hidden;
  border-radius: 10px;
  padding: 4px;
  padding-left: 0;
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
  background-color: white;

  &-in {
    &-btc {
      padding: 4px;
      padding-left: 8px;
      padding-right: 8px;
      background-color: #e98a0a;
      border-radius: 10px;
      color: white;

      .text {
        border-radius: 10px;
        font-weight: normal;
        font-size: 15px;
        background-color: white;
        color: #e98a0a;
        padding: 1px 5px;
        padding-top: 0;
      }
    }

    &-btc-lightning {
      padding: 4px;
      padding-left: 8px;
      padding-right: 8px;
      background-color: #7787d1;
      border-radius: 10px;
      color: white;

      .text {
        border-radius: 10px;
        font-weight: normal;
        color: white;
        font-size: 15px;
        background-color: transparent;
        padding-top: 0;

        > img {
          margin-right: 2px;
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}
